// const SMALL_BAG = 3.5;
// const LARGE_BAG = 10;


import {formatAddress} from "@/store/utils";

const MEALS_PER_BAG = 10;

const SNACKS = 0.5;
// const SMALL_MEAL = 0.83;
const REGULAR_MEAL = 1;
const QRCode = require('qrcode');
const md5 = require('md5');

const QRCodeSize = 200;
export default class BagCalc {
  static QRCodeSize() {
    return QRCodeSize
  }

  static async generateQRCodes(qrCodeValue, {size = null}) {
    return QRCode.toDataURL(qrCodeValue, {type: 'svg', width: size || QRCodeSize, height: size || QRCodeSize});
  }

  static computeBags(groupedByCustomer) {
    const driverCounts = {};
    const customerLabels = Object.keys(groupedByCustomer).map((customerName, index) => {
      const orders = groupedByCustomer[customerName];
      const count = BagCalc.getNumberOfBags(orders);
      const driver = orders[0].driver;
      const eta = orders[0].eta || orders[0].ship_date;
      const address = formatAddress(orders[0].address, {short: true});
      driverCounts[driver] = driverCounts[driver] || 0;
      driverCounts[driver] += count;
      const qrCodeValues = BagCalc.generateQRValues(customerName, {count});
      return {
        eta,
        customerName: customerName,
        driver,
        bins: BagCalc.getBins(orders),
        qrCodeValues,
        count,
        bagSize: BagCalc.getSizeOfBags(orders),
        sequence: index + 1,
        address_line_1 : address.split(',')[0],
        address_line_2: address.split(',').slice(1).join(', ')
      }
    })
    return {driverCounts, customerLabels};
  }

  static generateQRValues(qrBase, {count, orders}) {
    // strip base into just letters (ignore *, spaces, case changes)
    qrBase = qrBase.replace(/\W/g, '').toLowerCase();
    if (!count && orders) {
      count = BagCalc.getNumberOfBags(orders);
    }
    const qrCodeValues = [...Array(count).keys()]
      .map(i => `${qrBase}-${i + 1}`)
      .map(s => md5(s))
      .map(s => s.slice(0, 12));
    return qrCodeValues;
  }

  static getNumberOfBags(orders) {
    let total = this.getSizeValue(orders);
    return Math.ceil(total / MEALS_PER_BAG)
    // if (total >= 8) {
    //   total = total / LARGE_BAG;
    //   return Math.ceil(total);
    // } else {
    //   total = total / SMALL_BAG;
    //   return Math.ceil(total);
    // }
  }

  static getSizeOfBags(/* orders */) {
    return 'standard';
    // let total = this.getSizeValue(orders);
    // if (total >= 8) {
    //   return 'large';
    // }
    // return 'small';
  }

  static getBins(orders) {
    const orderBins = {};
    orders.forEach(o => {
      if (o.bin) {
        let bins = (o.bin || '').split(',').filter(b => !!b);
        for (const bin of bins) {
          orderBins[bin] = 1
        }
      }
    });

    function extractLetterAndNumber(s) {
      return s && (s[0] + s.split('-')[1]);
    }

    return Object.keys(orderBins).map(extractLetterAndNumber).sort();
  }

  static getSizeValue(orders) {
    let total = 0.0;
    let mealValue = REGULAR_MEAL; //size === 'small' ? SMALL_MEAL : REGULAR_MEAL;

    for (const item of orders) {
      if (item.tod !== 'extras') {
        total += item.quantity * mealValue;
      } else {
        total += item.quantity * SNACKS;
      }

      // if (item.tod === 'breakfast' && item.order_type !== 'chefs_choice' && stream !== 'keto') {
      //   total += item.quantity * SNACKS;
      // }
    }
    return total;
  }
}
